import React from 'react';
import styled from 'styled-components';
import Hero from './Hero';

const HeaderStyle = styled.header`
    width: 100%;
`;

const Header = () => {

    return (
			<HeaderStyle>
				<Hero />
			</HeaderStyle>
		);
}

export default Header;