import React from 'react';
import styled from 'styled-components';
import { withTrans } from '../../i18n/withTrans';
import { Section, Title } from '../Section';
import Card from './Card';

const ServicesSection = styled.section`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
`;

const Services = ({t, data}) => {

    return (
			<Section id="services">
				<Title data-aos="fade-left">{t('services.title')}</Title>
				<ServicesSection>
					{data.nodes.map((item) => (
						<Card
							key={item.id}
							title={item.title}
							description={item.short_description}
							image={item.image.localFile.childImageSharp.gatsbyImageData}
							link={item.slug}
						/>
					))}
				</ServicesSection>
			</Section>
		);
}

export default withTrans(Services);