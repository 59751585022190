import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

type Seo = {
	title?: string,
	description?: string,
	keywords?: string[],
	url?: string,
	lang: string
}

const SEO = ({ title, description, lang, url}: Seo) => {

	const data = useStaticQuery(graphql`
		query {
			allSite {
				nodes {
					siteMetadata {
						description
						keywords
						siteUrl
						title
						titleTemplate
					}
				}
			}
		}
	`);

	const site = data.allSite.nodes[0].siteMetadata;
	
    return (
			<Helmet
				htmlAttributes={{ lang: lang }}
				titleTemplate={`%s | ${site.titleTemplate}`} >
				<title>{title || site.title}</title>
				<meta name="description" content={description || site.description} />
				<link rel="canonical" href={`${url || site.siteUrl}`} />
				<meta name="og:type" content="website" />
			</Helmet>
		);
};

export default SEO;