import styled from 'styled-components';

export const CardStyle = styled.div`
	background: #ffffff;
	box-shadow: 0 10px 15px rgba(50, 50, 50, 0.2);
	border-radius: 6px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	max-width: 37.5rem;
	margin: 1.6rem;
`;

export const ImageContainer = styled.div`
	overflow: hidden;
	width: 100%;
	position: relative;
	max-height: 350px;

	img {
		object-fit: cover;
		width: 100%;
		transition: all 0.8s ease-in-out;

		&:hover {
			transform: scale(1.2);
		}
	}
`;

export const CardOverlay = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	opacity: 0;
	transition: opacity 0.7s ease-in-out;

	&:hover {
		opacity: 1;
	}
`;

export const Content = styled.div`
	padding: 20px 15px 30px;
	text-align: center;

	h3 {
		line-height: 42px;
	}

	p {
		padding: 5px 45px 15px;
	}

	@media ${(props) => props.theme.breakpoint.mobile} {
		font-size: 10px;
		p {
			padding: 5px 18px 15px;
		}
	}
`;
