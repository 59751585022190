import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { withTrans } from '../../i18n/withTrans';
import { Section, Title } from '../Section';
import { AboutSection } from './section';

const About = ({data, t}) => {
	const about = data.nodes[0];

	return (
		<Section id="about">
			<Title data-aos="fade-left">{t('about.title')}</Title>
			<AboutSection>
				<GatsbyImage
					image={getImage(
						about.image.localFile.childImageSharp.gatsbyImageData
					)}
					alt={about.image.alternativeText}
					data-aos="fade-right"
					style={{ maxHeight: '380px' }}
				/>
				<div className="about-content" data-aos="fade-in">
					<ReactMarkdown className="line-break">{about.content}</ReactMarkdown>
				</div>
			</AboutSection>
		</Section>
	);
};


export default withTrans(About);