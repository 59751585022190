import {
	Button, Modal,
	ModalBody,
	ModalCloseButton, ModalContent,
	ModalHeader, ModalOverlay,
	useDisclosure
} from "@chakra-ui/react";
import React from 'react';
import { withTrans } from '../../i18n/withTrans';

const ModalWindow = ({children, t}) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<>
			<Button
				size="lg"
				mt={12}
				colorScheme="purple"
				width="200px"
				onClick={onOpen}
				className="button-effect"
			>
				{t('hero.button')}
			</Button>
			<Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader fontSize="2xl">{t('form.title')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>{children}</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default withTrans(ModalWindow);