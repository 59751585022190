import styled from 'styled-components';
import HeroImage from '../../images/dsc9240-1024x683.jpg';

export const HeroStyles = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: linear-gradient(
			0deg,
			rgba(11, 32, 87, 0.4),
			rgba(11, 32, 87, 0.4)
		),
		url(${HeroImage});
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	color: #fff;
	font-weight: 400;
	text-align: center;
	position: relative;
	min-height: 100vh;

	h1 {
		line-height: 96px;
	}

	h1, h2 {
		color: #fff;
	}

	.scroll-down {
		position: absolute;
		left: 50%;
		bottom: 8vh;
		display: block;
		text-align: center;
		font-size: 20px;
		text-decoration: none;
		text-shadow: 0;
		width: 13px;
		height: 13px;
		border-bottom: 2px solid #fff;
		border-right: 2px solid #fff;
		-webkit-transform: translate(-50%, 0%) rotate(45deg);
		-moz-transform: translate(-50%, 0%) rotate(45deg);
		transform: translate(-50%, 0%) rotate(45deg);
		-webkit-animation: fade_move_down 2s ease-in-out infinite;
		-moz-animation: fade_move_down 2s ease-in-out infinite;
		animation: fade_move_down 2s ease-in-out infinite;
	}

	@-webkit-keyframes fade_move_down {
		0% {
			-webkit-transform: translate(0, -10px) rotate(45deg);
			opacity: 0;
		}
		50% {
			opacity: 1;
		}
		100% {
			-webkit-transform: translate(0, 10px) rotate(45deg);
			opacity: 0;
		}
	}

	@keyframes fade_move_down {
		0% {
			transform: translate(0, -10px) rotate(45deg);
			opacity: 0;
		}
		50% {
			opacity: 1;
		}
		100% {
			transform: translate(0, 10px) rotate(45deg);
			opacity: 0;
		}
	}
`;