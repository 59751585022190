import React from 'react';
import { FaPhoneAlt, FaRegCalendarCheck } from 'react-icons/fa';
import { HiOutlineLocationMarker, HiOutlineMail } from 'react-icons/hi';
import styled from 'styled-components';
import { withTrans } from '../../i18n/withTrans';
import { Section, Title } from '../Section';
import ContactForm from './ContactForm';
import StreetMap from './GoogleMap';

const FormSection = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	box-shadow: 0 10px 15px rgba(50, 50, 50, 0.2);
	background: #ffff;
	width: 100%;
	max-width: 820px;
	position: relative;

	@media ${(props) => props.theme.breakpoint.mobile} {
		flex-direction: column;
	}

	@media ${(props) => props.theme.breakpoint.tablet} {
		justify-content: center;
		align-items: center;
	}
`;

const TimeLine = styled.section`
	padding-left: 50px;

	&:before {
		content: '';
		position: absolute;
		background: #5039e7;
		width: 6px;
		height: 100%;
	}

	li {
		list-style-type: none;
		position: relative;
		padding-top: 40px;

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			transform: translateX(-50%);
			width: 4.5rem;
			height: 4.5rem;
			border-radius: 50%;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			background: #5039e7;
			z-index: 1;
		}

		p {
			padding: 15px 0 5px 45px;
			position: relative;
			bottom: 10px;

			a:hover {
				text-decoration: underline;
			}
		}

		svg {
			position: relative;
			top: 32px;
			right: 11px;
			z-index: 12;
		}
	}

	@media ${(props) => props.theme.breakpoint.tablet} {
		padding: 0 0 50px 50px;
		&:before {
			background: none;
			height: 100px;
		}

		li {
			padding-top: 0px;

			&:after {
				width: 4rem;
				height: 4rem;
			}

			p {
				padding: 15px 0 5px 32px;
			}
		}
	}
`;

const ContactInfo = ({t}) => {
	return (
		<TimeLine>
			<ul>
				<li>
					<HiOutlineLocationMarker size={25} color="white" />
					<p>{t('contact.address')}</p>
				</li>
				<li>
					<FaPhoneAlt size={20} color="white" />
					<p>
						<a href="tel:+371 29486016">+371 29486016</a>
					</p>
				</li>
				<li>
					<FaRegCalendarCheck size={24} color="white" />
					<p>{t('contact.workTime')}</p>
				</li>
				<li>
					<HiOutlineMail size={25} color="white" />
					<p>
						<a href="mailto:info@regina.lv">info@regina.lv</a>
					</p>
				</li>
			</ul>
		</TimeLine>
	);
};

const ContactSection = ({t}) => {
	return (
		<Section id="contact">
			<Title data-aos="fade-left">{t('contact.title')}</Title>
			<FormSection>
				<ContactForm captchaId="r-recaptcha" />
				<ContactInfo t={t} />
			</FormSection>
			<StreetMap />
		</Section>
	);
};

export default withTrans(ContactSection);
