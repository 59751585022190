import 'aos/dist/aos.css';
import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { About } from '../components/About';
import Brands from '../components/Brands';
import { ContactForm } from '../components/ContactForm';
import Header from '../components/Header';
import Layout from '../components/Layout/Layout';
import { SEO } from '../components/Seo';
import Services from '../components/Services';
import favicon16 from '../images/favicon/favicon-16x16.png';
import favicon32 from '../images/favicon/favicon-32x32.png';

const IndexPage = ({data}) => {
		const lang = data.about.nodes[0].locale;

		let AOS: any;
		useEffect(() => {
			const AOS = require('aos');
			AOS.init({
				once: true,
				duration: 2000
			});
		}, []);

		useEffect(() => {
			if (AOS) {
				AOS.refresh();
			}
		}, []);

	return (
		<Layout>
			<Helmet
				link=
				{[
					{
						rel: 'icon',
						type: 'image/png',
						sizes: '16x16',
						href: `${favicon16}`
					},
					{
						rel: 'icon',
						type: 'image/png',
						sizes: '32x32',
						href: `${favicon32}`
					},
					{ rel: 'shortcut icon', type: 'image/png', href: `${favicon32}` }
				]}
				/>
			<SEO lang={lang} />
			<Header />
			<Services data={data.services} />
			<Brands data={data.brands} />
			<About data={data.about} />
			<ContactForm />
		</Layout>
	);
};


export const query = graphql`
	query ($lang: String) {
		about: allStrapiAbout(filter: { locale: { eq: $lang } }) {
			nodes {
				content
				locale
				image {
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
				}
			}
		}

		services: allStrapiServices(filter: { locale: { eq: $lang } }) {
			nodes {
				category {
					category
				}
				id
				title
				slug
				locale
				content
				short_description
				image {
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(placeholder: BLURRED, width: 500)
						}
					}
				}
			}
		}

		brands: allStrapiBrands(filter: { locale: { eq: $lang } }) {
			nodes {
				id
				locale
				slug
				title
				short_description
				image {
					localFile {
						childImageSharp {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
				}
			}
		}
	}
`;

export default IndexPage;
