import React from 'react';
import styled from 'styled-components';

const MapWrapper = styled.div`
    margin-top: 40px;
    width: 100%;
    max-width: 850px;
    padding: 0 15px 0 15px;
`;

const StreetMap = () => {
    return (
			<MapWrapper>
				<iframe
					title="Google Maps"
					width="100%"
					height="450px"
					loading="lazy"
					allowFullScreen
					src={`https://www.google.com/maps/embed/v1/place?q=place_id:ChIJr_dGkRLO7kYRKcZAnvzohw8&key=${process.env.GOOGLE_MAPS_KEY}`}
				></iframe>
			</MapWrapper>
		);
}

export default StreetMap;