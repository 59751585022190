import styled from 'styled-components';

export const Section = styled.section<{ children?: any }>`
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 70px 0 120px;

	&:nth-child(even) {
		background: #e9f4f675;
	}

	@media ${(props) => props.theme.breakpoint.mobile} {
		padding: 30px 0 60px;
	}
`;

export const Title = styled.h1`
	font-size: clamp(2.5rem, 8vw, 5rem);
	font-weight: 500;
	position: relative;
	margin-bottom: 5rem;
	text-align: center;

	&::after {
		content: '';
		height: 2px;
		background-color: ${(props) => props.theme.colors.primary};
		width: 40%;
		position: absolute;
		left: 2px;
		bottom: 0.5rem;
	}

	@media ${(props) => props.theme.breakpoint.mobile} {
		margin-bottom: 1.4rem;

		&:after {
			bottom: 0;
		}
	}
`;