import { Alert, AlertDescription, AlertIcon, Button, FormControl, FormErrorMessage, FormLabel, Input, Textarea, VStack } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { HiOutlineMail } from 'react-icons/hi';
import Recaptcha from 'react-recaptcha';
import styled from 'styled-components';
import i18n from '../../i18n/i18next';
import { withTrans } from '../../i18n/withTrans';

const FormWrapper = styled.div`
	width: 100%;
	max-width: 360px;
	font-size: 2rem;

	form {
		padding: 30px;
	}
`;

const ContactForm = ({t, captchaId = 'g-recaptcha'}) => {

	const [isSubmited, setIsSubmited] = useState(false);
	const [captchaVerified, setCaptchaVerified] = useState(false);
	const [errors, setErrors] = useState<string[]>([]);

	const verifyCallback = (response: any) => {
		if(response) {
			setCaptchaVerified(true);
		}
	};

    return (
			<FormWrapper>
				<Formik
					initialValues={{
						name: '',
						email: '',
						message: ''
					}}
					onSubmit={async (values, actions) => {
						const options = {
							method: 'POST',
							body: JSON.stringify(values),
							headers: {
								'Content-Type': 'application/json'
							}
						};

						if (!captchaVerified) {
							setErrors((errors) => [...errors, i18n.t('form.errors.captcha')]);
							return;
						}

						await fetch('/.netlify/functions/sendmail', options)
							.then((res) => {
								actions.setSubmitting(false);
								actions.resetForm();
								if (res.ok) setIsSubmited(true);
							})
							.catch((err) => {
								actions.setSubmitting(false);
							});
					}}
				>
					<Form name="contact-form">
						<VStack spacing={7}>
							<Field name="name">
								{({ field, form }) => (
									<FormControl>
										<FormLabel htmlFor="name">{t('form.name')}</FormLabel>
										<Input
											{...field}
											id="name"
											placeholder={t('form.name')}
											size="lg"
										/>
										<FormErrorMessage>{form.errors.name}</FormErrorMessage>
									</FormControl>
								)}
							</Field>

							<Field name="email">
								{({ form, field }) => (
									<FormControl isRequired>
										<FormLabel htmlFor="email">{t('form.email')}</FormLabel>
										<Input
											{...field}
											id="email"
											placeholder={t('form.email')}
											size="lg"
										/>
										<FormErrorMessage>{form.errors.name}</FormErrorMessage>
									</FormControl>
								)}
							</Field>

							<Field name="message" component="textarea">
								{({ form, field }) => (
									<FormControl isRequired>
										<FormLabel htmlFor="message">{t('form.message')}</FormLabel>
										<Textarea
											{...field}
											placeholder={t('form.message')}
											size="lg"
											id="message"
											resize="none"
										/>
										<FormErrorMessage>{form.errors.name}</FormErrorMessage>
									</FormControl>
								)}
							</Field>
							<Recaptcha
								sitekey={process.env.RECAPTCHA_SITE_KEY}
								render="explicit"
								onloadCallback={() => console.info('Captcha Loaded')}
								verifyCallback={verifyCallback}
								elementID={captchaId}
							/>
							<Button
								mt={4}
								colorScheme="purple"
								type="submit"
								leftIcon={<HiOutlineMail />}
								isFullWidth={true}
								size="lg"
								fontSize="xl"
							>
								{t('form.button')}
							</Button>
							{isSubmited && (
								<Alert status="success">
									<AlertIcon />
									<AlertDescription fontSize={14}>
										{t('form.info')}
									</AlertDescription>
								</Alert>
							)}

							{errors.length > 0 && (
								<Alert status="error">
									<AlertIcon />
									<AlertDescription fontSize={14}>
										{errors.map((error) => (
											<p>{error}</p>
										))}
									</AlertDescription>
								</Alert>
							)}
						</VStack>
					</Form>
				</Formik>
			</FormWrapper>
		);
}

export default withTrans(ContactForm);