import { Button, Heading, Stack, Text } from '@chakra-ui/react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { withTrans } from '../../i18n/withTrans';
import { CardOverlay, CardStyle, Content, ImageContainer } from './CardStyles.style';

type Card = {
	title: string,
	description: string,
	image: any,
	link: string
}

export const Card = ({ title, description, image, link, t }: Card) => {

	return (
		<CardStyle data-aos="zoom-in">
			<ImageContainer>
				<GatsbyImage image={getImage(image)} alt={title} />
				<CardOverlay>
					<Link to={link}>
						<Button colorScheme="purple" size="lg" width="200px">
							{t('services.button')} →
						</Button>
					</Link>
				</CardOverlay>
			</ImageContainer>
			<Content>
				<Heading as="h3" size="lg">
					{title}
				</Heading>
				<Stack spacing={4} align="center">
					<Text fontSize="xl">{description}</Text>
					<Link to={link}>
						<Button
							colorScheme="purple"
							size="lg"
							width="200px"
							variant="outline"
						>
							{t('services.button')} →
						</Button>
					</Link>
				</Stack>
			</Content>
		</CardStyle>
	);
};

export default withTrans(Card);