import { Heading, Text } from "@chakra-ui/react";
import React from 'react';
import { withTrans } from '../../i18n/withTrans';
import ContactForm from '../ContactForm/ContactForm';
import { Container } from '../Layout/GlobalStyles';
import ModalWindow from '../Modal/ModalWindow';
import { HeroStyles } from './Hero.style';


const Hero = ({t}) => {

    return (
			<HeroStyles	>
				<Container>
					<Heading data-aos="fade-right" as="h1" size="4xl">
						{t('hero.title')}
					</Heading>
					<Heading as="h2" size="lg" data-aos="fade-left">
						{t('hero.sub_title')}
					</Heading>
					<Text mt={6} data-aos="fade-up">
						{t('hero.description')}
					</Text>
					<a href="#services">
						<div className="scroll-down"></div>
					</a>
					<ModalWindow>
						<ContactForm captchaId="f-recaptcha"/>
					</ModalWindow>
				</Container>
			</HeroStyles>
		);
}

export default withTrans(Hero);