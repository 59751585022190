import { Link, PageProps } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { withTrans } from '../../i18n/withTrans';
import { Section, Title } from '../Section';
import { CardStyle } from '../Services';

const BrandSection = styled.section`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	margin: auto;

	.brand-image-wrapper {
		display: flex;
		justify-content: center;
	}

	img {
		max-height: 315px;
		max-width: 245px;
		padding: 15px 0 20px 25px;
	}

	p {
		margin-top: 28px;
		text-align: center;
		padding-bottom: 15px;
	}
`;

const BrandCardStyle = styled(CardStyle)`
	padding: 15px;
	border: 1px solid #e2e2e2;
	transition: all 0.8s ease;
	position: relative;

	&:hover {
		border: 1px solid ${(props) => props.theme.colors.primary};
		transition: border 0.5s ease-in-out;
	}
`;

const Arrow = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	width: 45px;
	height: 45px;
	overflow: hidden;
	top: 0;
	right: 0;
	background-color: ${(props) => props.theme.colors.primary};
	border-radius: 0 4px 0 32px;
	opacity: 0.7;
	cursor: pointer;
	padding-bottom: 15px;

	&:hover {
		transition: opactiy 0.3s ease-in-out;
		opacity: 1;
	}

	.go-arrow {
		margin-top: -4px;
		margin-right: -4px;
		color: white;
		font-family: courier, sans-serif;
	}
`;

const ToBrands = () => {
	return (
		<Arrow>
			<div className="go-arrow">→</div>
		</Arrow>
	);
}


const Brands: React.FC<PageProps> = ({ data, t}) => {

	return (
		<Section id="brands">
			<Title data-aos="fade-left">{t('brands.title')}</Title>
			<BrandSection>
				{data.nodes.map((brand) => (
					<Link key={brand.id} to={`/${brand.locale}/${brand.slug}`}>
						<BrandCardStyle key={brand.id} data-aos="zoom-in">
							<ToBrands />
							<ReactMarkdown>{brand.short_description}</ReactMarkdown>
							<div className="brand-image-wrapper">
								<GatsbyImage
									image={getImage(
										brand.image.localFile.childImageSharp.gatsbyImageData
									)}
									alt={brand.title}
								/>
							</div>
						</BrandCardStyle>
					</Link>
				))}
			</BrandSection>
		</Section>
	);
};

export default withTrans(Brands);