import styled from 'styled-components';

export const AboutSection = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;

    .about-content {
        max-width: 55%;
        padding: 0 75px 0 50px;
        text-align: justify;
        line-height: 32px
    }

    @media ${(props) => props.theme.breakpoint.mobile} {
        flex-direction: column;

        img {
            padding-right: 0;
            padding-bottom: 25px;
        }

        .about-content {
            max-width: 100%;
            line-height: 22px;
            padding: 25px;
        }
    }

`;